<template>
    <div>
        <CModal
            title="Add Work Order"
            :show.sync="openModal"
            :no-close-on-backdrop="true"
            :centered="true"
            color="dark"
            >
            <template #header>
                <h6 class="modal-title">EDIT MEMBER</h6>
                <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
            </template>
            <CRow>
                <CCol sm="12">
                    <CInput
                        label="CARD NUMBER"
                        v-model="edit_form.card_no"
                        
                    />
                    <CInput
                        label="NAME"
                        v-model="edit_form.name"
                    />
                    <v-date-picker v-model="edit_form.birthdate" class="custom-date-container" mode="date" color="orange">
                        <template v-slot="{ inputValue, inputEvents }">
                            <label class="v-date-picker-label"> BIRTH DATE </label>
                            <input
                            class="px-2 py-1 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            style="width: 100%"
                            />
                        </template>
                    </v-date-picker>
                    <CInput
                        label="BENEFICIARY_NAME"
                        v-model="edit_form.beneficiary_name"
                    />
                    <CInput
                        label="BENEFICIARY_CONTACT"
                        v-model="edit_form.beneficiary_contact"
                        type="number"
                    />
                    <CInput
                        label="CONTACT NUMBER"
                        v-model="edit_form.contact_no"
                        type="number"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12" class="text-center">
                    <h4>VEHICLE OWNED 
                        <CIcon 
                            v-c-tooltip="'You can only add 3 Vehicle!'"
                            class="hover-pointer"
                             name="cil-info"/>
                    </h4>
                </CCol>
            </CRow>
            <div v-for="(item, index) in edit_form.property" :key="index">
                <vehicle 
                :vehicle="item"
                v-on:event_child="eventChild"
                />
            </div>
            <template #footer>
                <CButton @click="submit" id="btn-member-update" color="success" class="branding-btn">UPDATE</CButton>
                <CButton @click="cancelEdit" color="danger">Cancel</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import Vehicle from './EditVehicle';
import jQuery from 'jquery';

export default {
    components: {
        Vehicle,
    },
    data(){
        return { 
            openModal: false,
            edit_form: {
                card_no: '',
                name: '',
                contact_no: '',
                branch_id: '',
                birthdate: new Date(),
                beneficiary_name: '',
                beneficiary_contact: '',
                vehicleData: [
                {
                    vehicle_id: '',
                    plate_no: ''
                },
            ],
            },
            
            options: [
                {
                 value: 'test',
                 label: 'test'
                }
            ],
        }
    },
    props: ['editModalData'],
    watch: {
        editModalData(newData){
            this.edit_form = newData.data;
            this.openModal = true;
        }
    },
    computed:{
         ...mapGetters({ 
            branch: "branch/branches",
            vehicles: "vehicle/vehicles"
        }),
    },
    filters: {
        selectData(value){
            if(value){
                var i;
                var member = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].address){
                        member.push({ 
                        value: value[i].id, 
                        label: value[i].address
                        }); 
                    }
                }
                return member;

            }
        },
        vehicleFilter: function (value) {
            if(value){
                var i;
                var vehicle = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].vehicle_name){
                        vehicle.push({ 
                        value: value[i].id,
                        label: value[i].vehicle_name
                        }); 
                    }
                   
                }
                return vehicle;
            }
        },
        cardNoFilter(data){
          if(data) {
            var count = 0;
            var split = data.split('-');
            var add = parseInt(split[2])+1;
            var digit = add.toString().length;

            if(digit == 1){
              count = '000' + add;
            }else if(digit == 2){
              count = '00' + add;
            }else if(digit == 3){
              count = '0' + add;
            }else{
              count = add + 1;
            }
          }

          return new Date().getFullYear() + '-' + '000-' + count;
        }
    },
    methods: {
        eventChild(data, event){
            console.log(event.form);
            if(event == 'add'){
                if(this.edit_form.property.length == 3){
                    //
                }else{
                    this.edit_form.property.push({
                        id: '',
                        vehicle_id: '',
                        plate_no: '',
                        vehicle: {
                            vehicle_name: ''
                        }
                    });
                }
            } else {
                if(this.edit_form.property.length == 1){
                    //
                }else{
                    this.edit_form.property.splice(this.edit_form.property.indexOf(data), 1);
                }
                
            }
            
        },
        submit(){
            this.$root.btn_load(true, 'btn-member-update', 'UPDATE');
            this.$store.dispatch('member/updateMembers', this.edit_form).then(() => {
                this.openModal = false;
                this.$root.btn_load(false, 'btn-member-update', 'UPDATE');
                this.$store.dispatch('sales/getNewMemberNo');
            });
        },
        cancelEdit(){
            this.openModal = false;
            this.$store.commit('member/UPDATE_MEMBERS', JSON.parse(localStorage.getItem('edit_member')), {root: true})
            console.log(JSON.parse(localStorage.getItem('edit_member')));
        },
        alert(msg) {
            alert(msg);
        }
    },
    created(){
        this.$store.dispatch('branch/fetchBranch');
        this.$store.dispatch('vehicle/fetchVehicle');
    }
}
</script>
