<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 10"
        :dark="dark"
        :table-filter="true"
        @page-change="pageChange"
        pagination
        items-per-page-select
        :tableFilter='{ placeholder: "Search", label: " "}'
      >
        <template #NAME="{item}">
          <td>
            <span v-if="item.transaction_type == 'member'">{{item.member.name}}</span>
          </td>
        </template>
        <template #expiration_date="{item}">
          <td>
            <span>{{item.expiration_date}}</span>
          </td>
        </template>
        <template #status="{item}">
          <td v-if="new Date(item.expiration_date) > new Date()">
            <CBadge :color="getBadge('Active')">Active</CBadge>
          </td>
          <td v-else>
            <CBadge :color="getBadge('Banned')">Expired</CBadge>
          </td>
        </template>
        <template #vehicle_owned="{item}">
          <td>
              <div v-for="property in item.property" :key="property.id">
                <a @click="getValueCar(property)" href="#">{{property.vehicle.vehicle_name}} - {{property.plate_no}}</a>
              </div>
          </td>
        </template>
        <template #seller_name="{item}">
          <td v-if="item.user">
              {{item.user.name}}
          </td>
          <td v-else>
              <CBadge :color="getBadge('Inactive')">No Data</CBadge>
          </td>
        </template>
        <template #branch="{item}">
          <td>
            <span v-if="item.branch">{{item.branch.branch_address}}</span>
          </td>
        </template>
        <template #action="{item}">
            <td>
                <div>
                <!-- <CButton @click="getValueCar(item)" color="info" class="branding-btn"><CIcon name="cil-car-alt"/></CButton> &nbsp; -->
                <CButtonGroup>
                  <div style="position:relative;" v-if="$ability.can('update', 'GaTb7dw')">
                    <CButton size="sm" @click="getValue(item)" color="info"><CIcon name="cil-pencil"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="GaTb7dw" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                  </div>
                  <div style="position:relative;" v-if="$ability.can('update', 'GTTd9s6')">
                    <CButton size="sm" @click="getValueRenew(item)" color="warning"><CIcon name="cil-sync"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="GTTd9s6" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                  </div>
                  <div style="position:relative;" v-if="$ability.can('delete', 'FFEdIid')">
                    <CButton size="sm" @click="getValueDel(item)" color="danger"><CIcon name="cil-trash"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="FFEdIid" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                  </div>
                </CButtonGroup>
                </div>
            </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import moment from 'moment';
import RoleModal from "@/containers/RoleModal"

export default {
  name: 'Table',
  components: {
    RoleModal
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['card_no', 'name', 'contact_no', 'expiration_date', 'seller_name', 'status', 'vehicle_owned', 'branch', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  filters: {
      formatChange(data){
          return moment(String(data)).format('Y-MM-D');
      },
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    pageChange(page){
      console.log(action);
    },
    getValue(data){
      this.$emit('event_child', data, 'edit');
    },
    getValueRenew(data){
      this.$emit('event_child', data, 'renew');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete');
    },
    getValueCar(data){
      this.$emit('event_child', data, 'car');
    },
  }
}
</script>
