<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 10"
        :dark="dark"
        :table-filter="true"
        pagination
        items-per-page-select
      >
       <template #date="{item}">
          <td>
              {{item.transaction_date}}
          </td>
        </template>
        <template #services="{item}">
          <td>
              <ul v-for="data in item.temp_trans" :key="data.id">
                  <li>{{data.variation.services.services_name}}</li>
              </ul>
          </td>
        </template>
        <template #personnel_assigned="{item}">
          <td>
              <ul v-for="data in item.labors" :key="data.id">
                  <li v-if="data.washboy">{{data.washboy.name}}</li>
              </ul>
          </td>
        </template>
        <template #encoded_by="{item}">
          <td>
            <span v-if="item.user">
              {{item.user.name}}
            </span>
          </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Table',
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['date', 'services', 'personnel_assigned', 'encoded_by']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  filters: {
      formatChange(data){
          return moment(String(data)).format('Y-MM-D');
      },
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getValue(data){
      this.$emit('event_child', data, 'edit');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete');
    },
    getValueCar(data){
      this.$emit('event_child', data, 'car');
    },
  }
}
</script>
