<template>
    <div>
        <CModal
            title="Add Work Order"
            :show.sync="openModal"
            :no-close-on-backdrop="true"
            :centered="true"
            color="dark"
            >
            <template #header>
                <h6 class="modal-title">RENEWAL</h6>
                <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
                
            </template>
                <p class="m-0">Name: {{info.name}}</p>
                <p class="m-0">Contact No.: {{info.contact_no}}</p>
                <p class="m-0">Expiration Date: {{info.expiration_date}}</p>
                    <!-- <CInput
                        label="CARD NUMBER"
                        v-model="form.card_no"
                        readonly
                    />
                    <CInput
                        label="NAME"
                        v-model="form.name"
                    /> -->
            <v-date-picker v-model="form.expiration_date" class="custom-date-container" mode="date" color="orange">
                <template v-slot="{ inputValue, inputEvents }">
                    <label class="v-date-picker-label"> NEW EXPIRATION DATE </label>
                    <input
                    class="px-2 py-1 mt-3 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    style="width: 100%"
                    />
                </template>
            </v-date-picker>
            
            <div class="calculation">
                <h6 class="text-center">Payment Information</h6>
                <p class="m-0">Renewal Fee: ₱{{renewal_fee}}</p>
                <p class="m-0">Change: ₱{{change}}</p>
                <CInput
                    label="AMOUNT"
                    class="mt-3"
                    type="number"
                    v-model="form.amount"
                />
            </div>
            <template #footer>
                <CButton id="btn-renew" @click="submit" color="success" class="branding-btn">SUBMIT</CButton>
                <CButton @click="openModal = false" color="danger">Cancel</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>

export default {
    data(){
        return { 
            openModal: false,
            form: {
                id: '',
                expiration_date: new Date(),
                amount: 0,
                name: ''
            },
            info: []
        }
    },
    computed: {
        change(){
            var calc = this.form.amount - this.renewal_fee;
            if(calc > 0) return calc;
            else return 0;
        },
        renewal_fee(){
            return 300;
        }
    },
    props: ['renewModalData'],
    watch: {
        renewModalData(value){
            console.log(value);
            this.info = value.data;
            this.form.name = value.data.name;
            var oneYearFromNow = new Date(value.data.expiration_date)
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
            this.form.expiration_date = oneYearFromNow;
            this.form.id = value.data.id;
            this.openModal = true;
        },
    },
    methods: {
        submit(){
            this.$root.btn_load(true, 'btn-renew', 'SUBMIT');
            this.$store.dispatch('member/renewMembers', this.form).then(() => {
                this.openModal = false;
                this.$root.btn_load(false, 'btn-renew', 'SUBMIT');
            });
        }
    }
}
</script>
