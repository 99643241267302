<template>
    <div>
        <CModal
            title="Add Work Order"
            :show.sync="openModal"
            :no-close-on-backdrop="true"
            :centered="true"
            size="lg"
            color="dark"
            >
            <template #header>
                <h6 class="modal-title">ADD MEMBER</h6>
                <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
                <CIcon
                v-c-tooltip="'Every added member are counted as sales'"
                class="hover-pointer"
                name="cil-info"/>
            </template>
            <CRow>
                <CCol sm="6">
                    <CRow>
                        <CCol>
                            <v-date-picker v-model="form.date_added" class="custom-date-container" mode="date" color="orange">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <label class="v-date-picker-label">TRANSACTION DATE</label>
                                    <input
                                    class="px-2 py-1 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    style="width: 100%"
                                    />
                                </template>
                            </v-date-picker>
                        </CCol>
                        <CCol>
                            <div style="position:relative;">
                                <CInput
                                    label="CARD NUMBER"
                                    v-model="form.card_no"
                                    :disabled="cardNumberDisable"
                                />
                                <CLink @click="cardNumberDisable = !cardNumberDisable" class="btn-float-edit4"><CIcon size="sm" name="cil-pencil"/></CLink>
                            </div>
                        </CCol>
                    </CRow>
                    <CInput
                        label="NAME"
                        v-model="form.name"
                    />
                    <v-date-picker v-model="form.birthdate" class="custom-date-container" mode="date" color="orange">
                        <template v-slot="{ inputValue, inputEvents }">
                            <label class="v-date-picker-label"> BIRTH DATE </label>
                            <input
                            class="px-2 py-1 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            style="width: 100%"
                            />
                        </template>
                    </v-date-picker>
                    <CInput
                        label="BENEFICIARY_NAME"
                        v-model="form.beneficiary_name"
                    />
                    <CInput
                        label="BENEFICIARY_CONTACT"
                        type="number"
                        v-model="form.beneficiary_contact"
                    />
                    <CInput
                        label="CONTACT NUMBER"
                        type="number"
                        v-model="form.contact_no"
                    />
                    <v-date-picker v-model="form.expiration_date" class="custom-date-container" mode="date" color="orange">
                        <template v-slot="{ inputValue, inputEvents }">
                            <label class="v-date-picker-label"> EXPIRATION DATE </label>
                            <input
                            class="px-2 py-1 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                            style="width: 100%"
                            />
                        </template>
                    </v-date-picker><br>
                    
                    <CRow>
                        <CCol sm="12" class="text-center">
                            <h4>VEHICLE OWNED 
                                <CIcon
                                    v-c-tooltip="'You can only add 3 Vehicle!'"
                                    class="hover-pointer"
                                    name="cil-info"/>
                            </h4>
                        </CCol>
                    </CRow>
                    <div v-for="(item, index) in form.vehicleData" :key="index">
                        <vehicle 
                        :vehicle="item"
                        v-on:event_child="eventChild"
                        />
                    </div>
                </CCol>
                <CCol lg="6">
                    <h6 class="modal-title">RENEWAL</h6>
                        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
                            <!-- <CInput
                                label="CARD NUMBER"
                                v-model="form.card_no"
                                readonly
                            />
                            <CInput
                                label="NAME"
                                v-model="form.name"
                            /> -->
                    
                    
                    <div class="calculation">
                        <h6 class="text-center">Payment Information</h6>
                        <p class="m-0">Membership Fee: ₱{{membership_fee}}</p>
                        <p class="m-0">Change: ₱{{change}}</p>
                        <CInput
                            label="AMOUNT"
                            class="mt-3"
                            type="number"
                            v-model="form.amount"
                        />
                    </div>
                </CCol>
                
            </CRow>
            
            <template #footer>
                <CButton @click="submit" id="btn-member-add" color="success" class="branding-btn">ADD</CButton>
                <CButton @click="openModal = false" color="danger">Cancel</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import Vehicle from './Vehicle';

export default {
    components: {
        Vehicle,
    },
    data(){
        return { 
            openModal: false,
            cardNumberDisable: true,
            form: {
                card_no: '',
                name: '',
                birthdate: new Date(),
                beneficiary_name: '',
                beneficiary_contact: '',
                contact_no: '',
                branch_id: '',
                expiration_date: this.get_date(),
                user_id: '',
                date_added: new Date(),
                amount: 0,
                vehicleData: [
                {
                    vehicle_id: '',
                    plate_no: ''
                },
            ],
            },
            
            options: [
                {
                 value: 'test',
                 label: 'test'
                }
            ],
        }
    },
    props: ['addModalData'],
    watch: {
        addModalData(){
            this.$store.dispatch('sales/getNewMemberNo').then(response => {
                this.form.card_no = response;
            });
            this.openModal = true;
        },
    },
    computed:{
        membership_fee(){
            return 300;
        },  
        change(){
            var calc = this.form.amount - this.membership_fee;
            if(calc > 0) return calc;
            else return 0;
        }, 
         ...mapGetters({ 
            branch: "branch/branches",
            new_member_no: "sales/new_member_no",
            vehicles: "vehicle/vehicles"
        }),
        
    },
    filters: {
        selectData(value){
            if(value){
                var i;
                var member = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].address){
                        member.push({ 
                        value: value[i].id, 
                        label: value[i].address
                        }); 
                    }
                }
                return member;

            }
        },
        vehicleFilter: function (value) {
            if(value){
                var i;
                var vehicle = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].vehicle_name){
                        vehicle.push({ 
                        value: value[i].id,
                        label: value[i].vehicle_name
                        }); 
                    }
                   
                }
                return vehicle;
            }
        },
        cardNoFilter(data){
          if(data) {
            var count = 0;
            var split = data.split('-');
            var add = parseInt(split[2])+1;
            var digit = add.toString().length;

            if(digit == 1){
              count = '000' + add;
            }else if(digit == 2){
              count = '00' + add;
            }else if(digit == 3){
              count = '0' + add;
            }else{
              count = add + 1;
            }
          }

          return new Date().getFullYear() + '-' + '000-' + count;
        }
    },
    methods: {
        eventChild(data, event){

            if(event == 'add'){
                if(this.form.vehicleData.length == 3){
                    //
                }else{
                    this.form.vehicleData.push({
                        vehicle_id: '',
                        plate: ''
                    });
                }
            }else {
                if(this.form.vehicleData.length == 1){
                    //
                }else{
                    this.form.vehicleData.splice(this.form.vehicleData.indexOf(data), 1);
                }
            }
        },
        get_date(){
            var oneYearFromNow = new Date();
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
            return oneYearFromNow;
        },
        submit(){
            if(this.form.date_added){
                this.form.date_added = new Date(this.form.date_added).toLocaleDateString();
            }
            if(this.form.birthdate){
                this.form.birthdate = new Date(this.form.birthdate).toLocaleDateString();
            }
            if(this.form.expiration_date){
                this.form.expiration_date = new Date(this.form.expiration_date).toLocaleDateString();
            }
            this.openModal = false;
            this.$root.btn_load(true, 'btn-member-add', 'ADD');
            this.$store.dispatch('member/addMembers', this.form).then(() => {
                this.$root.btn_load(false, 'btn-member-add', 'ADD');
                this.form = {
                    card_no: '',
                    name: '',
                    beneficiary_name: '',
                    beneficiary_contact: '',
                    contact_no: '',
                    branch_id: '',
                    birthdate: new Date(),
                    date_added: new Date(),
                    expiration_date: this.get_date(),
                    vehicleData: [
                        {
                            label: '',
                            value: ''
                        }
                    ]
                }
                this.$store.dispatch('sales/getNewMemberNo');
            });
        },
        alert(msg) {
            alert(msg);
        }
    },
    created(){
        this.$store.dispatch('branch/fetchBranch');
        
        this.form.user_id = this.$store.getters['auth/user'].id;
        
        this.$store.dispatch('vehicle/fetchVehicle');
    }
}
</script>
