<template>
  <div>
    <CRow>
      <CCol :lg="colSize">
          <CCard>
          <CCardHeader style="position:relative;">
            <strong>Members</strong>
            <div class="card-header-actions">
              <CButton v-if="$ability.can('create', '57TSJ0D')" @click="addModalData = new Date()" style="position:absolute; right:10px; top:8px;" size="sm" class="branding-btn"><CIcon name="cil-plus"/></CButton>
              <RoleModal v-if="$store.state.permission.permission_settings" elementKey="57TSJ0D" customClass="headerBtn2" actionKey="create" :keyPage="$route.name"/>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTableWrapper
            :items="getShuffledUsersData()"
            striped
            v-on:event_child="eventChild"
            caption="Users"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="property_data" :lg="colSize">
          <CCard>
          <CCardHeader style="position:relative;">
            <strong>{{property_data.vehicle.vehicle_name}} - {{property_data.plate_no}}</strong>
            <div class="card-header-actions">
              <a @click="property_data = null; colSize = 12;" style="position:absolute; right:10px; top:8px;" class="hover-pointer" size="sm"><CIcon name="cil-exit-to-app"/></a>
            </div>
          </CCardHeader>
          <CCardBody>
             <CarPropertyTable
              :items="getShuffledPropertyData()"
              striped
              v-on:event_child="eventChild"
              caption="Users"
              />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <add-modal
    :addModalData="addModalData"
    />

    <RenewModal
    :renewModalData="renewModalData"
    />

    <edit-modal
    :editModalData="editModalData"
    />

    <delete-modal
    :deleteModal="deleteModal"
    />
  </div>
</template>

<script>
import CTableWrapper from './MemberTable.vue'
import CarPropertyTable from './CarPropertyTable.vue'
// import usersData from './users/UsersData'
import { mapGetters } from 'vuex';
import AddModal from './AddMember.vue';
import EditModal from './EditMember.vue';
import DeleteModal from './DeleteModal.vue';
import RoleModal from "../../containers/RoleModal"
import RenewModal from "./RenewModal"

export default {
  name: 'Dashboard',
  components: {
      CTableWrapper,
      AddModal,
      EditModal,
      DeleteModal,
      CarPropertyTable,
      RoleModal,
      RenewModal
  },
  data () {
      return {
        colSize: 12,
        property_data: '',
        addModalData: '',
        editModalData: '',
        renewModalData: '',
        deleteModal: '',
        tabs: [
            'AUTO-GROOMING',
            'MAINTENANCE',
        ],
        vehicle_selected: ''
      }
  },
  computed: {
    ...mapGetters({ 
        members: "member/members",
        property: "member/property"
    }),
  },
  filters: {
    
  },
  methods: {
    eventChild: function(data, status) {
        if(status == 'edit'){
          localStorage.removeItem("edit_member");
          localStorage.setItem("edit_member", JSON.stringify(data));
          this.editModalData = {
            trigger: new Date(),
            data: data
          };
        } else if(status == 'car'){
          this.property_data = data;
          const params = {
            id: data.id
          }
          this.$store.dispatch('member/findProperty', params);
          this.colSize = 6;
        } else if(status == 'renew') {
          this.renewModalData = {
            trigger: new Date(),
            data: data
          };
        } else{
          this.deleteModal = {
            trigger: new Date(),
            data: data
          };
        }
    },
    getShuffledUsersData () {
      return this.members;
    },
    getShuffledPropertyData() {
      return this.property;
    }
  },
  created(){
      this.$store.dispatch('member/fetchMembers');
      console.log(this.members);
  }
}
</script>
