<template>
    <CRow>
        <CCol lg="5">
            <div class="form-group">
                <label>VEHICLE</label>
                <v-select
                    class="mb-3 custom-new-select"
                    v-model='vehicle.vehicle_id'
                    :options="vehicles | vehicleFilter" 
                    placeholder="Select Vehicle"
                />
            </div>
        </CCol>
        <CCol lg="5">
            <CInput
                label="PLATE NUMBER"
                v-model="vehicle.plate_no"
                style="margin-left:-21px;"
            />
        </CCol>
        <CCol lg="2">
            <CButtonGroup style="margin-left:-11px;">
                <CButton @click="addInput" size="sm" color="success" class="branding-btn"><CIcon name="cil-plus"/></CButton>
                <CButton @click="minusInput" size="sm" color="danger"><CIcon name="cil-minus"/></CButton>
            </CButtonGroup>
        </CCol>
    </CRow>
</template>
<script>
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
     components: {
        vSelect,
    },
    computed: {
        ...mapGetters({ 
            vehicles: "vehicle/vehicles"
        }),
    },
    props: ['vehicle'],
    watch: {
        vehicle(newData){
            console.log(newData);
        }
    },
    filters: {
        vehicleFilter: function (value) {
            if(value){
                // console.log(value);
                var i;
                var vehicle = [];
                for (i = 0; i < value.length; i++) {
                    if(value[i].vehicle_name){
                        vehicle.push({ 
                        value: value[i].id,
                        label: value[i].vehicle_name
                        }); 
                    }
                   
                }
                return vehicle;
            }
        },
    },
    data(){
        return {
            options: [
                {
                 value: 1,
                 label: 'test'
                }
            ]
        }
    },
    methods: {
        addInput(index){
            this.$emit('event_child', this.vehicle, 'add');
        },
        minusInput(index){
            this.$emit('event_child', this.vehicle, 'minus');
        },
    },
    created(){
        this.$store.dispatch('vehicle/fetchVehicle');
    }
}
</script>